<template>
    <div class="mine">
		<div class="user_list">
			<template v-for="item in userList">
				<div class="ul_div" :key="item.user_id">
					<div class="uld_left">
						<div class="uldl_img">
							<img :src="item.avatar">
						</div>
						<div class="uldl_name">
							<span>{{item.name}}</span>
							<span>{{item.telphone}}</span>
						</div>
					</div>
					<div class="uld_right">
						<div class="uldr_span" v-if="item.openid==''" @click="toUserCode(item)">
							<span>邀请绑定</span>
						</div>
						<div class="uldr_del" @click="deleteUser(item)">
							<span>删除</span>
						</div>
					</div>
				</div>
			</template>
			
		</div>

		<div class="user_bottom">
			<div class="add_btn" @click="toAddUser">
				<span>新增</span>
			</div>
		</div>
    </div>
</template>
<script>
import until from '@/api/until.js'
import studio_login_api from '@/api/teaching_studio_no_login.js'
import tool_api from '@/api/teaching_studio_tool.js'
import studio_api from '@/api/teaching_studio_teaching_studio_api.js'

export default {
	name: 'mine',
	data(){
		return{
			userList:[],

		}
	},
	components: {
		
	},
	mounted(){
		document.title="用户管理"
		this.getUserList();
	},
	methods:{
		//获取工作室所有用户
		async getUserList() {
			let app_id = this.$root.app_id;
			let data = await studio_api.Teaching_studio_apiMemberUserListGet({ app_id });
			if (data.errcode == 0) {
				this.userList = data.other;
				
			}
		},
		deleteUser(item){
			this.$dialog.confirm({
				title: '标题',
				message: '是否确认删除',
			})
			.then(async () => {
				let app_id=this.$root.app_id;
				let user_id=item.user_id
				this.$toast.loading({
					message: "加载中...",
					forbidClick: false,
					duration: 0
				});
				let data=await studio_api.Teaching_studio_apiMemberUserOutDelete({app_id,user_id});
				if(data.errcode==0){
					this.$toast("删除成功")
					this.getUserList();
				}
			})
			.catch(() => {
				// on cancel
			});
		},
		toUserCode(item){
			this.$router.push("/usercode?user_id="+item.user_id)
		},
		toAddUser(){
			this.$router.push("/adduser")
		}



	}
}
</script>
<style scoped>
	.mine{
		width: 100%;
		height: 100vh;
		background: #F6F6F6;
		display: inline-block;
		overflow: auto;
		padding: 0 30px;
		box-sizing: border-box;
		padding-bottom: 166px;
	}
	.user_list{
		width: 100%;
		display: inline-block;
	}
	.ul_div{
		width: 100%;
		height: 160px;
		background: #FFFFFF;
		border-radius: 20px 20px 20px 20px;
		opacity: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 30px;
		box-sizing: border-box;
		margin-top: 24px;
	}
	.uld_left{
		display: flex;
		align-items: center;
		justify-content: flex-start;

	}
	.uldl_img{
		width: 88px;
		height: 88px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}
	.uldl_img img{
		width: 100%;
		height: 100%;
	}
	.uldl_name{
		margin-left: 24px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		font-size: 28px;
		color: #333333;
		font-weight: bold;
		line-height: 40px;
	}
	.uldl_name span:last-child{
		font-size: 24px;
		color: #999999;
		line-height: 33px;
		margin-top: 8px;
		font-weight: 400;
	}
	.uld_right{
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.uldr_span{
		width: 160px;
		height: 64px;
		background: #CEFFE3;
		border-radius: 32px 32px 32px 32px;
		opacity: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
		color: #02E4AE;
	}
	.uldr_del{
		width: 120px;
		height: 64px;
		background: #FFE9E8;
		border-radius: 32px 32px 32px 32px;
		opacity: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
		color: #FF5A4E;
		margin-left: 24px;
	}
	.deleteUser{

	}
	.user_bottom{
		width: 100%;
		height: 120px;
		background: #FFFFFF;
		box-shadow: 0px -3px 6px 1px rgba(0,0,0,0.1);
		border-radius: 0px 0px 0px 0px;
		position: fixed;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		bottom: 0;
	}
	.add_btn{
		width: 640px;
		height: 88px;
		background: #4E89FF;
		border-radius: 44px 44px 44px 44px;
		opacity: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 28px;
		color: #FFFFFF;
	}
</style>
